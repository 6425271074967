<script>
import appConfig from "@/app.config";
import { required, email } from "vuelidate/lib/validators";
import API from '@/api.js';

/**
 * Forgot Password component
 */
export default {
  page: {
    title: "Forgot Password",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: "",
      submitted: false,
      resetSuccess: false,
      error: null,
      title: "Recoverpwd",
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    // Try to register the user in with the email, fullname
    // and password they provided.
    async tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        try {
          const resp = await this.axios.post(
            API.resetPassword.path(),
            {
              "email": this.email
            }
          );
          if (resp.status === 204) {
            // this.$router.push('/login');
            this.resetSuccess = true;
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
  mounted() {
    if (this.$auth.check()) {
      this.$router.push('/');
    }
  }
};
</script>

<template>
  <div class="container-fluid" style="overflow-x: hidden;">
    <div class="row" style="min-height: 100vh;">
      <div class="col-lg-5 login-side-col bg-primary">
        <div class="row h-100">
          <div class="col-12 align-self-center">
            <img class="my-auto align-self-center" :src="require('@/assets/images/logo-ae-w-alt.png')" />
          </div>
        </div>
      </div>
      <div class="col-lg-7 align-self-center h-100" style="">
          <div clas="row justify-items-center">
            <div class="col-6 mx-auto">
              <div class="text-center mt-2 align-self-center">
                  <h3 class="text-primary">Reimposta la tua password</h3>
                  <p class="text-muted" v-if="!resetSuccess">
                    Inserisci l'e-mail associata al tuo account Adapta Easy e riceverai un link di recupero
                  </p>
                </div>
            </div>
            <div class="col-lg-8 col-md-10 col-12 mx-auto align-self-center">
              <div class="p-2 mt-4">
                    <div
                      class="alert alert-info text-left mb-4"
                      role="alert"
                      v-if="resetSuccess"
                    >
                      Abbiamo inviato una mail con il link per reimpostare la tua password all'indirizzo: <b>{{ email }}</b> <br/>
                    </div>
                    <div class="my-4 text-center w-100" v-if="resetSuccess">
                      <router-link class="btn btn-primary" to="/login">Torna alla pagina di login</router-link>
                    </div>
                    <form @submit.prevent="tryToReset" v-if="!resetSuccess">
                      <div class="mb-3">
                        <label for="useremail">Indirizzo E-mail</label>
                        <input
                          type="email"
                          v-model="email"
                          class="form-control"
                          id="useremail"
                          placeholder="Inserisci l'indirizzo e-mail"
                          :class="{
                            'is-invalid': submitted && $v.email.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.email.required"
                            >Campo obbligatorio</span
                          >
                          <span v-if="!$v.email.email"
                            >Indirizzo e-mail non valido</span
                          >
                        </div>
                      </div>
                      <div class="row mb-0">
                        <div class="col-12 text-center">
                          <button class="btn btn-primary w-sm" type="submit">
                            Reimposta
                          </button> &nbsp; &nbsp;
                          <router-link to="/login" class="btn btn-outline-primary">
                            Torna al login
                          </router-link>
                        </div>
                      </div>
                      <!-- <div class="mt-4 text-center">
                        <p class="mb-0">
                          Ricordi la tua password ?
                          <router-link
                            to="/login"
                            class="fw-medium text-primary"
                            >Vai al Login</router-link
                          >
                        </p>
                      </div> -->
                    </form>
                  </div>
            </div>
            <div class="col-12 align-self-end">
              <p class="mt-5 text-center">
                © {{ new Date().getFullYear() }} - Powered by Vysio s.r.l.
              </p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-login {
  background-color: #171f36;  
}
.login-side-col {
  text-align: center;
}
.login-side-col img {
    width: 40%;
}
</style>
